<template>
  <div class="row">
    <div class="col-md-12">
      <b-card class="trazo-no-overflow" header-tag="header" footer-tag="footer">
        <b-card-text>
          <div class="payrollTableHeader">
            <h6 class="mb-5">Lista de boletas</h6>
          </div>
          <b-table
            head-variant="dark"
            :items="payrolls"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(actions)="data">
              <div class="h5 mb-0">
                <label
                  size="sm"
                  variant="light"
                  class="btn btn-light"
                  @click="donwloadPayroll(data.item)"
                >
                  <b-icon icon="eye"></b-icon>
                </label>
                <label
                  size="sm"
                  variant="light"
                  class="btn btn-light ml-2"
                  v-if="!payrollIsSigned(data.item)"
                  @click="signPayroll(data.item)"
                >
                  <b-icon icon="vector-pen"></b-icon>
                </label>
              </div>
            </template>
          </b-table>
          <div class="text-center" v-if="payrolls.length === 0">
            No existen boletas registradas
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.payrollTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
</style>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";

import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      payrolls: [],
      currentPage: 1,
      perPage: 12,
      fields: [
        {
          key: "period",
          sortable: true,
          label: "Periodo"
        },
        {
          key: "document_type",
          sortable: true,
          label: "Tipo de documento"
        },
        {
          key: "name",
          sortable: true,
          label: "Empresa"
        },
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.payrolls.length;
    }
  },
  methods: {
    donwloadPayroll(payroll) {
      let partialUrl = payroll.original;
      if (payroll.admin_signed) {
        partialUrl = payroll.admin_signed;
      }
      if (payroll.employee_signed) {
        partialUrl = payroll.employee_signed;
      }
      let url = `${process.env.VUE_APP_API_URL}/uploads/${partialUrl}`;
      if (url) {
        this.downloadFile(url);
      } else {
        this.$bvToast.toast(
          "Error al cargar boleta, por favor comuniquese con RR.HH",
          {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        );
      }
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
    async getMyPayrolls() {
      try {
        const {
          data: { payrolls }
        } = await ApiService.get("/api/payroll/me");
        this.payrolls = payrolls;
      } catch (error) {
        console.log("error", error);
      }
    },
    async signPayroll(payroll) {
      if (payroll) {
        try {
          await ApiService.get(
            `/api/payroll/${payroll.payroll_id}/sign/employee`
          );
          this.getMyPayrolls();
          this.$bvToast.toast("Boleta firmada correctamente.", {
            title: `Éxito`,
            variant: "succes",
            solid: true
          });
        } catch (error) {
          this.$bvToast.toast(
            "Ha ocurrido un error, no se pudo firmar la boleta.",
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          );
        }
      } else {
        this.$bvToast.toast("La boleta no existe, intente con otra boleta.", {
          title: `Error`,
          variant: "danger",
          solid: true
        });
      }
    },
    payrollIsSigned(payroll) {
      if (payroll.require_employee_sign === false) {
        return true;
      }
      if (!payroll.admin_signed) {
        return false;
      }
      if (!payroll.employee_signed) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getMyPayrolls();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Recursos humanos" },
      { title: "Mis boletas" }
    ]);
  },
  components: {}
};
</script>
